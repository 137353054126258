import React from "react";

// Components
import { Global } from "@emotion/core";
import Layout from "../../components/Layouts/Layout";
import SEO from "../../components/Utilities/SEO";
import SectionProduct from "../../components/Sections/SectionProduct";
import ImgProductJournal1 from "../../components/Images/Journal/products/ImgProductJournal1";

const data = {
  category: "Notecard",
  images: [<ImgProductJournal1 />],
  title: "Lord, Teach Us To Pray Notecard",
  description:
    "Prayer is simply talking to God. He beacons and delights for us to spend time with Him. Just when troubles becomes jampacked, impactful, and intense, it’s in the stillness of the hour, prayer is always available for us to communicate with the Almighty.",
  price: 5.99,
  link: ""
};

export default () => {
  return (
    <Layout scrollLock>
      <SEO
        title="Know Believe Fulfill | Anana Vestige"
        description="Come discover a lifestyle to KNOW how much value you possess, to BELIEVE in the truth of who you are, and to FULFILL the great task you have been given."
      />
      <Global
        styles={{
          ".nav-item-notecards": {
            color: "white"
          }
        }}
      />
      <SectionProduct data={data} />
    </Layout>
  );
};
